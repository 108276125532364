import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { isDevEnv } from '.';
import en from 'public/locales/en.json';
import id from 'public/locales/id.json';
import ms from 'public/locales/ms.json';
import th from 'public/locales/th.json';
import tl from 'public/locales/tl.json';
import vi from 'public/locales/vi.json';
import zh from 'public/locales/zh.json';
const LOCALES = ['en', 'id', 'ms', 'th', 'tl', 'vi', 'zh'];
const resourcesMap = {
    en,
    id,
    ms,
    th,
    tl,
    vi,
    zh,
};
i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
    supportedLngs: LOCALES,
    backend: {
        loadPath: (lng) => resourcesMap[lng],
    },
    detection: {
        order: ['navigator'],
        caches: ['localStorage'],
    },
    fallbackLng: 'en',
    debug: isDevEnv(),
});
export default i18n;
