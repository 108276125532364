/* eslint-disable import/prefer-default-export */
import { DEFAULT_STATUSES_TO_QUERY_FOR_HISTORY_BOOKINGS } from '@constants/constants';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { DEV_HOSTNAME, PRD_HOSTNAME, STG_HOSTNAME, } from 'src/constants/appConfigs';
dayjs.extend(duration);
export const isNotRunningTest = () => process.env.JEST_WORKER_ID === undefined;
export const isProdEnv = () => { var _a; return ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname) === PRD_HOSTNAME; };
export const isStagingEnv = () => { var _a; return ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname) === STG_HOSTNAME; };
export const isDevEnv = () => { var _a; return ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname) === DEV_HOSTNAME; };
export const isBranchEnv = () => { var _a; return (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname.endsWith(`-${STG_HOSTNAME}`); };
export const isUsingStagingEnv = () => isStagingEnv() || isDevEnv() || isBranchEnv();
export const getEnv = () => {
    if (isProdEnv())
        return 'prd';
    if (isStagingEnv())
        return 'stg';
    if (isDevEnv())
        return 'dev';
    if (isBranchEnv())
        return 'stg';
    return '';
};
export const getApiEnv = () => (isProdEnv() ? 'prd' : 'stg');
export const getFullEnv = (env) => {
    switch (env) {
        case 'stg':
            return 'staging';
        case 'prd':
            return 'production';
        case 'misc':
            return 'miscellaneous';
        default:
            return '';
    }
};
export const getStringBetween = (str, start, end) => {
    const result = str.match(new RegExp(`(?<=${start})(.*)(?=${end})`));
    return (result === null || result === void 0 ? void 0 : result[1]) || '';
};
export const formatLongText = (src, maxLength) => {
    if (src.length > maxLength) {
        return `${src.substring(0, maxLength)}...`;
    }
    return src;
};
export const formatJobDuration = (milliseconds, customFormat) => {
    if (milliseconds < 0) {
        return '-';
    }
    if (milliseconds < 1000) {
        return `${milliseconds}ms`;
    }
    return dayjs.duration(milliseconds).format(customFormat || 'H[h] m[m] s[s]');
};
export const camelCase = (str) => str
    .replace(/[-_]([a-z])/g, g => g[1].toUpperCase())
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase())
    .replace(/\s+/g, '');
export const formatTimestamp = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
    if (!date) {
        return '-';
    }
    if (typeof date === 'number' && date < 0) {
        return '-';
    }
    return dayjs(date).format(format);
};
export const capitalizeFirstLetter = (s) => s ? s[0].toUpperCase() + s.slice(1) : '';
export const accFromEmail = (email) => (email === null || email === void 0 ? void 0 : email.replace('@grabtaxi.com', '')) || '';
export const urlify = (text = '') => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, url => `<a href="${url}">${url}</a>`);
};
export const queryStringify = (obj) => `?${new URLSearchParams(obj).toString()}`;
export const isHistoricalOrActive = (status) => {
    if (!status) {
        return 'active';
    }
    if (DEFAULT_STATUSES_TO_QUERY_FOR_HISTORY_BOOKINGS.includes(status)) {
        return 'history';
    }
    return 'active';
};
