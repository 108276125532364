var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import App from '@components/App';
import { datadogLogs } from '@datadog/browser-logs';
import Storage from 'grab-storage';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import '@duxton-web/inter';
import './globalStyles.less';
import { ScribeProvider } from './hooks/useScribe';
window.Grab = {
    config: {},
    storage: Storage,
};
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: 60000 /* Data is consider stale after 1 minute */,
            refetchOnWindowFocus: false,
        },
    },
});
function renderApp() {
    const container = document.getElementById('root');
    if (!container)
        return;
    const root = createRoot(container);
    root.render(React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(ScribeProvider, null,
            React.createElement(BrowserRouter, null,
                React.createElement(App, null)))));
}
window
    .fetch(require('config.json'))
    .then((configResponse) => __awaiter(void 0, void 0, void 0, function* () {
    if (configResponse.status !== 200)
        return;
    window.Grab.config = yield configResponse.json();
    const isProductionEnv = process.env.NODE_ENV === 'production';
    const datadogClientTokenExists = window.Grab.config.datadog && window.Grab.config.datadog.clientToken;
    if (isProductionEnv && datadogClientTokenExists) {
        datadogLogs.init({
            clientToken: window.Grab.config.datadog.clientToken,
            site: 'datadoghq.com',
            service: window.Grab.config.appId,
            env: window.Grab.config.env,
            version: process.env.APP_VERSION,
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
        });
        window.onerror = (_message, _source, _lineno, _colno, error) => {
            if (error) {
                datadogLogs.logger.error(error.message || '', {
                    error: { stack: error.stack },
                });
            }
        };
    }
    renderApp();
}))
    .catch(err => {
    datadogLogs.logger.error(err.message, { error: { stack: err.stack } });
});
