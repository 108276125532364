// import { ProtectedRoute } from '@data-platform/concedo-auth';
import ProtectedRoute from '@components/protected-route';
import Spinner from '@components/Spinner';
import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import WithUpdateWorker from '@helpers/update-worker/WithUpdateWorker';
import '@helpers/i18n';
import '@helpers/dayjs';
const LoginPage = lazy(() => import('@pages/login/LoginPage'));
const HomeLayout = lazy(() => import('src/layouts/HomeLayout'));
const App = () => (React.createElement(React.Suspense, { fallback: React.createElement(Spinner, null) },
    React.createElement(Routes, null,
        React.createElement(Route, { path: "/login", element: React.createElement(LoginPage, null) }),
        React.createElement(Route, { path: "/*", element: React.createElement(ProtectedRoute, null,
                React.createElement(HomeLayout, null)) }))));
export default WithUpdateWorker(App);
