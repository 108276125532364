export const ERROR_MESSAGES = {
    locked: 'the account is locked',
};
export const ERROR_REASONS = {
    invalid: 'INVALID_ACCOUNT',
};
export const BOOKING_STATUSES = [
    'TO_BE_ASSIGNED',
    'IN_TRANSIT',
    'COMPLETED',
    'CANCELLED',
    'RELEASED',
    'CRITICAL',
    'ASSIGNED',
];
export const BOOKING_STATUS_MAP = {
    TO_BE_ASSIGNED: 'TO_BE_ASSIGNED',
    IN_TRANSIT: 'IN_TRANSIT',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    RELEASED: 'RELEASED',
    CRITICAL: 'CRITICAL',
    ASSIGNED: 'ASSIGNED',
};
export const MAPPING_BOOKING_STATUS_TEXT_COLOR = {
    TO_BE_ASSIGNED: '#575757',
    IN_TRANSIT: '#005239',
    COMPLETED: '#005239',
    CANCELLED: '#824703',
    RELEASED: '#D73A2A',
    CRITICAL: '#824703',
    ASSIGNED: '#005239',
};
export const MAPPING_BOOKING_STATUS_BACKGROUND_COLOR = {
    TO_BE_ASSIGNED: '#F5F5F5',
    IN_TRANSIT: '#D9FCDE',
    COMPLETED: '#D9FCDE',
    CANCELLED: '#FFFAC0',
    RELEASED: '#FDF2F2',
    CRITICAL: '#FFFAC0',
    ASSIGNED: '#D9FCDE',
};
export const BOOKING_LIST_TYPES = {
    unassigned: [BOOKING_STATUS_MAP.TO_BE_ASSIGNED, BOOKING_STATUS_MAP.CRITICAL],
    assigned: [BOOKING_STATUS_MAP.ASSIGNED, BOOKING_STATUS_MAP.IN_TRANSIT],
    all: [
        BOOKING_STATUS_MAP.TO_BE_ASSIGNED,
        BOOKING_STATUS_MAP.CRITICAL,
        BOOKING_STATUS_MAP.ASSIGNED,
        BOOKING_STATUS_MAP.IN_TRANSIT,
    ],
};
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE = 1;
export const DEFAULT_STATUSES_TO_QUERY_FOR_ACTIVE_BOOKINGS = [
    BOOKING_STATUS_MAP.TO_BE_ASSIGNED,
    BOOKING_STATUS_MAP.CRITICAL,
    BOOKING_STATUS_MAP.IN_TRANSIT,
    BOOKING_STATUS_MAP.ASSIGNED,
];
export const DEFAULT_STATUSES_TO_QUERY_FOR_HISTORY_BOOKINGS = [
    BOOKING_STATUS_MAP.COMPLETED,
    BOOKING_STATUS_MAP.RELEASED,
    BOOKING_STATUS_MAP.CANCELLED,
];
export const DEFAULT_STATUSES_TO_QUERY_FOR_ALL_BOOKINGS = [
    ...DEFAULT_STATUSES_TO_QUERY_FOR_ACTIVE_BOOKINGS,
    ...DEFAULT_STATUSES_TO_QUERY_FOR_HISTORY_BOOKINGS,
];
export const PICKUP_TIME_ASC_SORT = 'pickupTime ASC';
export const PICKUP_TIME_DESC_SORT = 'pickupTime DESC';
export const BOOKING_ENDED_TIME_ASC_SORT = 'bookingEndedTime ASC';
export const BOOKING_ENDED_TIME_DESC_SORT = 'bookingEndedTime DESC';
export const SORT_BY_PICKUP_TIME = 'pickupTime';
export const SORT_BY_BOOKING_ENDED_TIME = 'bookingEndedTime';
export const ACTIVE_BOOKING_TYPE = 'active';
export const HISTORY_BOOKING_TYPE = 'history';
