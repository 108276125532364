import GrabStorage from 'grab-storage';
import { camelCase } from '@helpers/index';
export const TOKEN_HEADER_KEY = 'X-XSRF-TOKEN';
export const KC_TOKEN_KEY = '__KC_TOKEN__';
export const TOKEN_EMPTY_ERROR = 'TOKEN_EMPTY_ERROR';
export const TOKEN_NAME = '__TOKEN__';
export const REFRESH_TOKEN = '__REFRESH_TOKEN__';
export const CLIENT_TOKEN = '__CLIENT_TOKEN__';
export const CONCEDO_STATE = '__CONCEDO_STATE__';
export const CONCEDO_ENABLE = '__CONCEDO_ENABLE__';
export const CONCEDO_REDIRECT_URL = '__CONCEDO_REDIRECT_URL__'; // This URL will be redirected to after login successfully
export const CONCEDO_REFRESH_TIME_ID = '__CONCEDO_REFRESH_TIME_ID__'; // This URL will be redirected to after login successfully
export const UNAUTH_ERROR_CODE = 'UNAUTHORIZED';
export const SWITCH_CONCEDO_ALERT_CODE = 'SWITCH_CONCEDO_ALERT_CODE';
export const SWITCH_GOOGLE_ALERT_CODE = 'SWITCH_GOOGLE_ALERT_CODE';
export const SWITCH_AUTH_ERROR = 'Authentication method is changed';
export function setConcedoState(state) {
    GrabStorage.setItem(CONCEDO_STATE, state);
}
export function getConcedoState() {
    return GrabStorage.getItem(CONCEDO_STATE);
}
export function removeConcedoState() {
    GrabStorage.removeItem(CONCEDO_STATE);
}
export function getToken() {
    return GrabStorage.getItem(TOKEN_NAME);
}
export function getRefreshToken() {
    return GrabStorage.getItem(REFRESH_TOKEN);
}
export function setToken(token, refreshToken = '') {
    GrabStorage.setItem(TOKEN_NAME, token);
    if (refreshToken)
        GrabStorage.setItem(REFRESH_TOKEN, refreshToken);
}
export function removeToken() {
    return GrabStorage.removeItem(TOKEN_NAME);
}
export const camelizeToken = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map(v => camelizeToken(v));
    }
    if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce((result, key) => (Object.assign(Object.assign({}, result), { [camelCase(key)]: camelizeToken(obj[key]) })), {});
    }
    return obj;
};
export const encodeState = (key, redirectPath) => {
    const state = JSON.stringify({ key, redirectPath });
    return Buffer.from(state).toString('base64');
};
export const decodeState = (encodedStr) => {
    try {
        const decodedStr = Buffer.from(encodedStr, 'base64').toString('ascii');
        const state = JSON.parse(decodedStr);
        return state;
    }
    catch (_a) {
        /* Failed to decode state, return empty object */
    }
    return {};
};
