/* istanbul ignore file */
import React, { useEffect } from 'react';
import { notification } from '@duxton-web/ui';
import worker from './worker';
const onServiceWorkerUpdate = (eventName) => {
    switch (eventName) {
        case 'update-installed':
            notification.info({
                duration: 0,
                message: 'New Version Update',
                description: 'A new version has been downloaded, please refresh the page to update to latest version.',
                placement: 'bottomLeft',
            });
            break;
        default:
    }
};
const WithUpdateWorker = (WrappedComponent) => {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    const ComponentWithWorker = (props) => {
        useEffect(() => {
            worker.register(onServiceWorkerUpdate);
        }, []);
        return React.createElement(WrappedComponent, Object.assign({}, props));
    };
    ComponentWithWorker.displayName = `WithUpdateWorker(${displayName})`;
    return ComponentWithWorker;
};
export default WithUpdateWorker;
