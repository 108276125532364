import React, { createContext, useEffect } from 'react';
import scribe from '@grab-x/scribe-web-sdk';
import { TOKEN_NAME } from '@helpers/loginHelper';
const getEnvironment = () => {
    if (window.location.hostname.includes('.grab.com')) {
        return 'production';
    }
    if (window.location.hostname.includes('.stg-myteksi.com')) {
        return 'staging';
    }
    return 'dev';
};
const initScribe = () => {
    try {
        scribe.initialize('fop', getEnvironment() === 'production');
        scribe.setAuthToken(localStorage.getItem(TOKEN_NAME));
    }
    catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error while initializing scribe web SDK');
    }
};
const ScribeContext = createContext(undefined);
const useScribe = (defaultData = {}) => {
    const context = React.useContext(ScribeContext);
    if (!context) {
        throw new Error('useScribe must be used within a ScribeProvider');
    }
    const enhancedTrackEvent = (eventName, data) => {
        context.trackEvent(eventName, Object.assign(Object.assign({}, defaultData), data));
    };
    const enhancedGetVariable = (variableName, countryCode) => context.getVariable(variableName, countryCode);
    return {
        trackEvent: enhancedTrackEvent,
        getVariable: enhancedGetVariable,
    };
};
const ScribeProvider = ({ children }) => {
    const trackEvent = (eventName, parameters = {}, force = false) => {
        scribe.sendEvent(eventName, parameters);
        if (force) {
            scribe.forceUpdate(); // for crucial events or before the page redirects
        }
    };
    const getVariable = (variableName, countryCode) => {
        const predefinedFacetsBuilder = scribe.getPredefinedFacetsBuilder();
        const predefinedFacets = predefinedFacetsBuilder()
            .country(countryCode)
            .buildFacets();
        return scribe.getVariable(variableName, predefinedFacets);
    };
    useEffect(() => {
        initScribe();
    }, []);
    return (React.createElement(ScribeContext.Provider, { value: { trackEvent, getVariable } }, children));
};
export { ScribeProvider, useScribe };
