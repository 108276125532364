/* Referenced from web-app/packages/grab-service-worker/helpers/serviceWorker.js */
/* istanbul ignore file */
// Custom event names
const UPDATE_INSTALLED = 'update-installed';
function register(callback) {
    if (process.env.NODE_ENV !== 'production' ||
        !('serviceWorker' in navigator)) {
        return;
    }
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.origin);
    if (publicUrl.origin !== window.location.origin) {
        // Our service worker won't work if PUBLIC_URL is on a different origin
        // from what our page is served on. This might happen if a CDN is used to
        // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
        return;
    }
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
    navigator.serviceWorker.register(swUrl).then(registration => {
        registration.addEventListener('updatefound', () => {
            const newWorker = registration.installing;
            if (newWorker == null)
                return;
            newWorker.addEventListener('statechange', () => {
                if (newWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                        callback(UPDATE_INSTALLED);
                    }
                }
            });
        });
    });
}
export default {
    register,
};
